var languageConfigs = {
  user: {
    identityId: "Indentity ID",
    profile: "Personal Profile",
    profileID: "Profile ID",
    setting: "Setting",
    logout: "Logout",
    phone: "Phone",
    fullName: "Full Name",
    dob: "Birthdate",
    gender: "Gender",
    email: "Email",
    address: "Address",
    occupation: "Occupation",
    country: "Country",
    call: "Call",
    password: "Password",
    profileUrl: "Profile URL",
  },
  home: {
    welcome: "Welcome",
    haveaniceday: "Have a nice day at work!",
    report: "Report",
    event: "Event",
    episode: "Episode",
    notification: "Notification",
  },
  auth: {
    or: "Or",
    emailorphone: "Email / Phone",
    login: "Login",
    signup: "Signup",
    verifyphone: "Verify your phone number",
    sendcode: "Send code",
    alreadyhaveaccount: "Already have an account?",
    donthaveaccount: "Dont have an account?",
  },
  register: {
    editlogo: "Edit logo",
    verifyCode: "Verify Code",
    becomedoctor: "Register doctor account",
    registerclinic: "Register Clinic",
    registeraccount: "Register Account",
    register: "Register",
    continue: "Continue",
    additionalinfo: "Additional Info",
    male: "Male",
    female: "Female",
    completed: "Completed",
    startusingapp: "Start using app",
    message1:
      "These information is used to provide best experience and services for our users.",
    message2: "Thank you for signing up at Sandrasoft!",
    message3:
      "We will contact you within 24h to verify. In the meatime you can start using our apllication.",
    cancalMonitor: "Stop monitoring this medicine",
  },
  common: {
    welcome: "Welcome",
    subscribeInvitation: "Subscribe Invitation",
    inputDeviceID: "Input Device ID",
    hour: "Hour",
    manualMode: "Manual mode",
    calibsMethod: "Calibration method",
    series: "Series",
    low: "Low",
    normal: "Normal",
    high: "High",
    veryHigh: "So High",
    trial: "Measure",
    statistics: "Statistics",
    profile: "Profile",
    connectedDevice: "Previously connected devices",
    undefined: "Anonymous episodes",
    finishEpisode: "Finish episode",
    finishEpisodeConfirm: "Do you want to finish this episode?",
    cannotFindAnyDevice: "Cannot find any device. Please try again later",
    deleteDevice: "Are you sure to delete this device?",
    deleteInvitation: "Are you sure to delete this invitation?",
    acceptSubscribe: "Connection opened. Now {0} can see your new data.",
    cancelSubscribe:
      "Connection closed. Now {0} can no longer see your new data.",
    areYouSureToClose:
      "Are you sure you want to close, data will not be saved?",
    close: "Close",
    showQr: "Show personal QR",
    personalQr: "Personal QR",
    searchByPatient: "Search by name or phone",
    chooseAll: "Choose all",
    scanQr: "Scan QR",
    patient: "Patient profile",
    findDevice: "Find device",
    scanQrDevice: "Scan QR",
    confirmConnectDevice: "Would you like to connect to device ",
    cuffPressure: "Cuff pressure",
    connectDevice: "Connect device",
    bleNotAvailable: "Bluetooth not supported in this device",
    heartRate: "Heart rate",
    sys: "Systolic",
    dias: "Diastolic",
    clinicalNote: "Clinical note",
    saveAndFinish: "Save & finish",
    successMessage: "Action completed successfully!",
    configEpisode: "Configuration",
    maxInflatePressure: "Maximum inflation pressure (mmHg)",
    calibsPerHour: "Calibrarion per hour",
    valueCannotBeEmpty: "Value cannot be empty!",
    errorMessage: "Action completion failed. Please try again later",
    episodeName: "Episode Name",
    averageStats: "Average statistics",
    bloodPressure: "Blood pressure",
    chart: "Chart data",
    times: "times",
    date: "Date",
    back: "Back",
    search: "Search",
    checkin: "Check-in",
    appoint: "Appointment",
    choose: "Choose",
    selecttoview: "Select an item to view",
    selectdatetime: "Select date & time",
    create: "Create",
    info: "Information",
    detail: "Detailed information",
    select: "Select",
    datetime: "Date & time",
    confirm: "Confirm",
    confirmed: "I agree to the Term & Condition",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    finish: "Finish",
    selectfile: "Select file",
    today: "Today",
    at: "At",
    invite: "Invite",
    user: "User",
    notprovide: "Not provided",
    nodata: "No data found",
    awesomenodata: "Awesome! No notification yet.",
    lastvisits: "Latest episodes",
    verifyUpdate: "Starting application. Please wait a moment...",
    serverunavailable: "Server is under maintainance. Please come back later.",
    backtohome: "Back to home",
    timeStart: "Time start",
    timeEnd: "Time end",
    examcontent: "Examination content",
    clinicaldata: "Clinical note for doctor",
    account: "Account",
    report: "Report",
    month: "Month",
    rebooking: "Re-appointment",
    open: "Open website",
    title: "Title",
    headline: "Headline",
    share: "Share",
    unshare: "Unshare",
    confirmShare:
      "Share this information to be viewed by other people. Do you wish to continue?",
    confirmUnshare:
      "Unshare this information, people cannot see this information. Do you wish to continue?",
    confirmDeleteContact:
      "Remove this phone from your contact, but data will not be erased. Do you wish to continue?",
    change: "Change",
    oldPassword: "Old password",
    newPassword: "New password",
    retypePassword: "Re-type new password",
    changePasswordFailMessage: "Change password failed!",
    changePasswordSuccessMessage:
      "Change password success! Please login again.",
    waitingStateConnecting: "Connecting...",
    waitingStateRinging: "Ringing...",
    waitingStateCalling: "Calling...",
    waitingStateNoAnswer: "No anwser.",
    conversationStart: "Start of conversation",
    datapoints: "Data points",
    allData: "All data points",
    chooseLanguage: "Choose language",
    waitNextTurn: "Wait for next turn",
    confirmConnect: "Do you want to connect with",
    sync: "Sync data from server",
    phoneNumberError: "Phone number not valid",
    add: "Add",
    reminder: "Reminder",
    reminder_nodata:
      "Congratulations!<br/>You have no upcomming reminders yet!",
    contact: "Contact list",
    selecticon: "Select icon",
    choosetime: "Choose time",
    configtime: "Config time",
    time: "Time",
    dose: "Dose",
    minute: "Minute",
    day: "Day",
    repeat: "Repeat",
    norepeat: "No repeat",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
    observer: "Observers",
    participant: "Participants",
    removeMedicineConfirmMessage:
      "Are you sure to delete? Data cannot be retrieved.",
    completeMedicine: "Are you sure you want to complete this?",
    information: "Information",
    history: "History",
    itstime: "It's time",
    doitnow: "Do it now",
    missed: "Missed",
    contactagain: "Do it again",
    shareyourthought: "Take note",
    received: "received",
    every: "every",
    drugName: "Drug name",
    inProgress: "In progress",
    completed: "Completed",
    doctorInCharge: "Doctor in charge",
    call: "Call",
    description: "Description",
    medicineList: "Medicine list",
    termCondition: "Term & Condition",
    hospitalinformation: "Hospital information",
    injectioninstruction: "Injection Instruction",
  },
  medicine: {
    medicine: "Medicine",
    name: "Medicine name",
    description: "Description",
    documents: "Attachments",
    capsule: "Capsule",
    dropper: "Dropper",
    bottle: "Bottle",
    tube: "Tube",
    box: "Box",
    inject: "Injection",
    message: "Reminder message",
    defaultMessage: "Mommy, please take medicine on time.",
    remindBefore: "Remind before",
  },
  weekday: {
    T2: "Mon",
    T3: "Tue",
    T4: "Web",
    T5: "Thu",
    T6: "Fri",
    T7: "Sat",
    CN: "Sun",
  },
  nav: {
    home: "Home",
    history: "Reminders",
    setting: "Account",
    information: "Information",
  },
  error: {
    phonealreadyinuse: "Phone number already in use, please use it to login!",
    verifycodefailed: "Cannot send verification code to this number!",
    retrysendcode: "Retry with a different phone number",
    bodyMovement: "Dont move or talk.",
    noHandInCuff: "No hand in cuff",
    cuffLoose: "Cuff loose.",
    irregularPulse: "Irregular pulse.",
    pulseExceed: "Pulse exceed upper limit.",
    pulseLower: "Pulse lower than limit.",
    improperPosition: "Improper position.",
    overshoot: "Dont move or talk.",
    measurementFailed: "Unknown error.",
    maxdistanceFailed: "Max distance failed.",
    noSysDia: "No data detected.",
    periodFailed: "Standard period detection failed.",
    unknownError: "Unknown error.",
    errorCode: "Error code",
    errorOccured: "Error Occured",
    deviceNameError: "Device name not correct.",
    cannotFindAccount: "Cannot find account info.",
    pinEmptyError: "Battery is less than 20%. Please charge device to use!",
  },
};

export default languageConfigs;
